/* eslint-disable jsx-a11y/anchor-is-valid */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import React from 'react'
import { BackgroundImg, Img } from 'react-cloudimage-responsive'
import Section from './components/Section'
import './css/App.scss'
import logo_cera from './img/logo_cera.jpg'
import logo_parents_zens_rond from './img/logo_parents_zens_rond.png'
import mpec_logo_new_2 from './img/mpec_logo_new_2.png'

function App () {

  const getContentBlueBand = () => {
    return (
      <div className="d-flex justify-content-center w-100">
        <div className="col-md-8 col-md-offset-2 text-center single-line">
          <a href={process.env.REACT_APP_URL_UJCALC} target="_blank" rel='noopener noreferrer' className="btn btn-ghost btn-md">Trouver des activités pour mes enfants<i
            className="fa fa-arrow-right ml-3"/></a>
        </div>
      </div>
    )
  }

  const getSection1 = () => {
    return (

      <div className="max-h d-flex w-100 justify-content-between flex-column flex-md-row">
        <div className="col-sm-12 col-md-6 block-txt-simple text-center shadow max-w">
          <img src={mpec_logo_new_2} alt="Logo maplaceencreche" className="logo-size"/>
          <p>La Caisse d'Epargne Rhône-Alpes met à votre disposition de nouveaux services innovants
            dédiés à la parentalité, du début de la grossesse au statut
            de grand-parent.
            Retrouvez l'ensemble des informations liées à ces nouveaux services et inscrivez-vous
            aux conférences et ateliers proposés.
          </p>
        </div>
        <div className="col-sm-12 col-md-6 mt-5 mt-md-0 max-w imgRadius no-padMobile">
          <Img
            src={'enfant-livre.jpg'}
            ratio={1}
            params={
              {
                gravity: 'auto'
              }
            }
            sizes={
              {
                '(max-width: 700px)': { h: '10vh' },
                '(min-width: 701px)': { w: '450px', h: '350px' }
              }
            }
          />
        </div>
      </div>
    )
  }

  const getSection2 = () => {
    return (
      <div className="container">
        <div className="no-gutters shadow">
          <div className="col-12">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link title" type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                      Place en crèche
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Votre service Ressources Humaines met à votre disposition des places en crèche
                      pour les salariés répondant à ces critères :</p>
                    <ul>
                      <li>Avoir un enfant de 3 ans ou moins ou attendre un enfant</li>
                      <li>Etre salarié en CDI à plein temps à la CERA et période d'essai passée</li>
                      <li>Demande de place faite auprès de la mairie et refusée, ou en liste d'attente</li>
                      <li>Ne pas être en préavis de départ</li>
                    </ul>
                    <a href={process.env.REACT_APP_URL_COMMISSION} target="_blank" rel='noopener noreferrer'>S'inscrire pour obtenir une place en crèche</a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo" aria-expanded="false"
                            aria-controls="collapseTwo">
                      Conférence - Parents déconfinés - Retrouver un équilibre vie pro / vie perso
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                     data-parent="#collapseTwo">
                  <div className="card-body">
                    <p><strong>Thématique de la conférence :</strong> Parents déconfinés - Retrouver un équilibre vie pro/vie perso</p>
                    <p>La période de confinement a permis de toucher du doigt une autre dimension de l’équilibre pro-perso,
                      celle où l’espace-temps dédié au travail s’entremêle complètement avec l’espace-temps dédié à la famille.
                      Et chacun a pu ainsi faire ses expériences, plus ou moins positives. Avec l’envie d’en tirer des réflexions
                      sur « l’après » : plus de temps en famille, ralentir, joie de retrouver son travail, manger mieux… </p>
                    <ul>
                      <li>Changer de regard sur son équilibre grâce aux nouvelles clés de lecture apportées par cette période de (dé)confinement</li>
                      <li>Bénéficier de conseil pour concilier travail et famille dans cette période</li>
                      <li>Passer à l’action grâce à un outil concret, la roue de la vie</li>
                    </ul>
                    <p><strong>Intervenante :</strong> Clémence Pagnon</p>
                    <p>
                      <strong>Date :</strong> 11 mars 2021<br/>
                      <strong>Heure :</strong> 12h30-13h30<br/>
                      <strong>Lieu :</strong> Cette conférence sera accessible à distance en webinar et disponible en replay.
                    </p>
                    <p>Pour revoir la conférence, rendez-vous sur le lien ci-dessous.</p>
                    <a href='https://youtu.be/g7oNKigGnAA' className='btn btn-sd btn-primary' target="_blank" rel='noopener noreferrer'>
                      <i className={'fas fa-eye'}/> Revoir
                    </a>
                    <a href={'https://maplaceencreche.sharepoint.com/:b:/s/public/Ed0vkMFO8Q1Ajyv8IXmAzXMBJxoTv0bbJrQzO1sD9JrmIg?e=OkEywk'} className={'btn btn-info'}
                       target={'_blank'} rel={'noopener noreferrer'}>
                      <i className={'fas fa-file-pdf'}/> Mémo
                    </a>
                    <a href={'https://maplaceencreche.sharepoint.com/:b:/s/public/EaFTodsRsZJEmOT82McfxHMB_KF_SA1vrGhQ_EWN_s9e8Q?e=SUbynO'} className={'btn btn-info'}
                       target={'_blank'} rel={'noopener noreferrer'}>
                      <i className={'far fa-file-pdf'}/> Exercice Roue de la Vie
                    </a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                      Atelier – La régulation émotionnelle
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p><strong>Thématique de l'atelier 1 :</strong> La régulation émotionnelle – Un outil simple pour apprendre à gérer ses émotions en famille</p>
                    <p>Basée sur une approche récente, fondée sur le ressenti physique plutôt que l’intellect,
                      la régulation émotionnelle apprend l’autonomie afin de résoudre les émotions perturbatrices quand elles surviennent.
                      • Les participants acquièrent une méthode d’auto-questionnement qu’ils peuvent aussi utiliser en différé,
                      à froid pour analyser les situations de trouble émotionnel, au niveau personnel ou professionnel</p>
                    <p>Les points forts :
                      <ul>
                        <li>Cas concrets.</li>
                        <li>L’approche essentiellement corporelle, qui permet d’expérimenter directement en atelier les bénéfices de la régulation</li>
                        <li>Un mémo pour dérouler la méthode de manière autonome et apprendre à décoder les signaux d’alarme</li>
                      </ul></p>
                    <p><strong>Intervenante :</strong> Valérie Bardet - L’intervenante, ancienne ingénieure,
                      aujourd’hui ostéopathe et coach en entreprise, formée à la régulation émotionnelle.</p>
                    <p><strong>Session 1 :</strong><br/>
                      <strong>Date : </strong> Lundi 29 mars 2021 <br/>
                      <strong>Heure : </strong> 12h45 – 13h30 <br/>
                      <strong>Lieu : </strong> Cet atelier sera accessible à distance en webinar<br/>
                    </p>
                    <p><strong>Session 2 :</strong><br/>
                      <strong>Date : </strong> Mardi 6 avril 2021<br/>
                      <strong>Heure : </strong> 12h45 – 13h30<br/>
                      <strong>Lieu : </strong> Cet atelier sera accessible à distance en webinar<br/>
                    </p>
                    <p><strong>Atelier limité aux 25 premiers inscrits.</strong></p>
                    <p><strong>Important : Une fois inscrit, votre présence à l'atelier est obligatoire.</strong></p>
                    <a
                      href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZbof8Reg6ihHuXMreAOUro1UMzk5S1dZRklOUzZaODk0WjlNTUM5T09CMS4u'
                      target="_blank" rel='noopener noreferrer'>S'inscrire</a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour" aria-expanded="false"
                            aria-controls="collapseFour">
                      Atelier – Activer ses supers-pouvoirs
                    </button>
                  </h5>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p><strong>Thématique de l'atelier 2 : </strong>Activer ses « supers-pouvoirs » ! Ou comment utiliser ses forces pour s'épanouir en tant que parent</p>
                    <p>Le fait de nourrir et d’utiliser ses forces et talents au quotidien est considéré comme une des stratégies les plus efficaces pour s’épanouir personnellement
                      et professionnellement. Cette stratégie a notamment un impact très positif sur notre niveau de confiance, de vitalité, d’émotions positives, de résistance au
                      stress… </p>
                    <p className={'mt-0'}>L’atelier invite à ce changement de perspective : bien identifier ses talents, apprendre à mieux les activer au quotidien, pour soi et
                      dans la relation aux autres.</p>
                    <p>Les points forts :
                      <ul>
                        <li>Jeu et convivialité au cœur du dispositif d’animation.</li>
                        <li>Une réflexion qui s’invite avant l’atelier par un questionnaire et se prolonge à la suite pour faire de l’atelier un vrai temps d’échange et
                          d’approfondissement
                        </li>
                      </ul>
                    </p>
                    <p><strong>Intervenante :</strong> Perrine Lhote - L’intervenante, ancienne consultante en stratégie, a réinventé sa manière de travailler grâce à la
                      psychologie positive.</p>
                    <p><strong>Session 1 :</strong><br/>
                      <strong>Date : </strong> Mardi 30 mars 2021<br/>
                      <strong>Heure : </strong> 12h45 – 13h30 <br/>
                      <strong>Lieu : </strong> Cet atelier sera accessible à distance en webinar <br/>
                    </p>
                    <p><strong>Session 2 :</strong><br/>
                      <strong>Date : </strong> Jeudi 8 avril 2021 <br/>
                      <strong>Heure : </strong> 12h45 – 13h30 <br/>
                      <strong>Lieu : </strong> Cet atelier sera accessible à distance en webinar <br/>
                    </p>
                    <p><strong>Atelier limité aux 25 premiers inscrits.</strong></p>
                    <p><strong>Important : Une fois inscrit, votre présence à l'atelier est obligatoire.</strong></p>
                    <a
                      href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZbof8Reg6ihHuXMreAOUro1UQlFMUjlWODNRMTlIVEw4R1FGUzVYN0dIVS4u'
                      target="_blank" rel='noopener noreferrer'>S'inscrire</a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive" aria-expanded="false"
                            aria-controls="collapseFive">
                      Garde Complémentaire
                    </button>
                  </h5>
                </div>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p><strong>Besoin d'une solution de garde pour vos enfants ?</strong></p>
                    <p>Baby-sitting, sortie d'école, sortie de crèche, horaire variables, vacances scolaires,
                      nous mettons à votre disposition un service de complément de garde pour vous permettre de mieux concilier vie
                      perso et vie pro.</p>
                    <p>La CERA vous offre un accès gratuit (abonnement d'un an) à la plateforme Babysittor.
                      Babysittor est une plateforme de mise en relation entre baby-sitters et parents pour la garde d'enfants</p>
                    <p>Leur algorithme de recommandation, leur indice de confiance et la certification des profils permettent aux parents
                      de confier leurs enfants les yeux fermés à un inconnu à la dernière minute.</p>
                    <p><strong>Pour accéder à ce service : </strong></p>
                    <ul>
                      <li>Télécharger l'application</li>
                      <li>Renseigner vos informations</li>
                      <li>Confirmer votre baby-sitter</li>
                      <li>Payer votre baby-sitter via l'application <strong>(à la charge de chaque famille)</strong></li>
                      <li>Entrer la clé dédiée pour bénéficier d'un an d'abonnement gratuit</li>
                    </ul>
                    <p><strong>Important : le nombre d'abonnements est limité</strong></p>
                    <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZXX0Sxj4gidIpkuLUSMoP1pURVlBMlFTUU0xRTY4TkNMWDI0U0lER05TWC4u'
                       rel='noopener noreferrer' target="_blank">Demander
                      la clé dédiée</a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseSix" aria-expanded="false"
                            aria-controls="collapseSix">
                      Parents zens
                    </button>
                  </h5>
                </div>
                <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Besoin d'un coup de pouce pour gérer une situation familiale ponctuelle ?</p>
                    <p>Nous mettons à votre disposition un service de conseil et d'information individualisé
                      sur des sujets très variés.</p>
                    <ul>
                      <li>Être parent : réagir face aux remarques de son entourage, gérer sa culpabilité...</li>
                      <li>Les étapes de la vie : alimentation du bébé, sommeil, propreté, devoirs...</li>
                      <li>Les défis comportementaux: violence, manque de respect, opposition, organisation des tâches ménagères...</li>
                      <li>La gestion des relations : la jalousie au sein de la fratrie...</li>
                    </ul>
                    <p>Des experts se tiennent à votre disposition pour échanger sur vos questions familiales, parentales à
                      tout moment. Ils vous aident à aborder la situation sous un nouvel angle et à amorcer une réflexion
                      pour trouver la réponse adaptée à votre besoin.</p>
                    <p><strong>Important : le nombre de session est limité</strong></p>
                    <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZXX0Sxj4gidIpkuLUSMoP1pUQTVBUTE3STcxWDg2OEFOSDVBMUpIQzFWWS4u' target="_blank"
                       rel='noopener noreferrer'>
                      Bénéficier du service
                    </a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSeven">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseSeven" aria-expanded="false"
                            aria-controls="collapseSeven">
                      Retour Parents zens
                    </button>
                  </h5>
                </div>
                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Pourquoi : Pour gérer dans les meilleures conditions l’arrivée prochaine de votre futur enfant et ses premières
                      semaines de vie au sein de votre foyer.</p>
                    <p>Education positive, vie de couple, vie de famille, gestes du quotidien... Chaque nouveau parent bénéficie
                      d’un atelier personnel pour son couple pendant la grossesse puis d’un suivi pendant 3 mois.</p>
                    <p>Pour qui : Suivi personnel de chaque couple</p>
                    <p>Format : Visio-conférence</p>
                    <p>Intervenant : L’atelier du futur parent</p>
                    <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZXX0Sxj4gidIpkuLUSMoP1pUQzNMNVczMk85OTYzRElUUFNGUjVDUlZJVC4u' target="_blank"
                       rel='noopener noreferrer'>
                      Bénéficier du service
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const getSection3 = () => {
    return (
      <div className="container">
        <div className="no-gutters question-mail shadow block-txt-simple2">
          <div className="col-12">
            <div className="sizetext d-flex justify-content-center p-3 margin--p plus">
              <p><span className="color-mpec">Vous avez des questions ? Contactez votre experte famille Cécile Fleutiaux : </span><br/>
                <a href="mailto:cecile.fleutiaux@maplaceencreche.com" target="_blank" rel='noopener noreferrer'
                   className="d-flex flex-row underline justify-content-center">
                  cecile.fleutiaux@maplaceencreche.com
                </a>
                06 68 08 88 52</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="m-auto ioej">
        <img src={logo_cera} alt="logo-cera" className="logo-cera"/>
      </div>
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-12 no-pad'}>
            <BackgroundImg
              src="petite_fille_robe_bleu.jpg"
              style={{ background: '50% 50% / cover no-repeat' }}
            >
              <header className="masthead bg-header w-100">
                <div className="d-flex flex-column justify-content-center">
                  <div className={'d-flex col-12 col-sm-3 justify-content-center mb-sm-0 mb-4'}>
                    <img src={logo_parents_zens_rond} alt="logo-pz" className="logo-pz"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="blueround shadow">
                      <p className="text-uppercase nav-head">vos services parentalité</p>
                    </div>
                  </div>
                </div>
              </header>
            </BackgroundImg>
          </div>
          <Section classSup={'bg-blue w-100'} content={getContentBlueBand()}/>
          <div className="container">
            <Section content={getSection1()} classSup={'max-h mb-5'}/>
            <Section classSup={'w-100'} content={getSection2()}/>
            <Section classSup={'w-100'} content={getSection3()}/>
          </div>
          <footer className="block-section w-100">
            <div className="col-12 col-sm-7">
                    <span className="type--fine-print">©
                        <span className="update-year">2019</span> maplaceencrèche
                    </span>
              <a className="type--fine-print" href="https://www.maplaceencreche.com/legale/cgu/" target="_blank"
                 rel="noopener noreferrer"> CGU</a>
              <a className="type--fine-print" href="https://www.maplaceencreche.com/legale/mentions-legales/"
                 target="-_blank" rel="noopener noreferrer"> Mentions légales</a>
              <a className="type--fine-print"
                 href="https://www.maplaceencreche.com/qui-sommes-nous/notre-mission/" target="_blank"
                 rel="noopener noreferrer"> Notre
                mission</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default App